var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    staticClass: "mt-n2",
    attrs: {
      items: _vm.data,
      "item-text": "name",
      "item-value": "id",
      loading: _vm.loading,
      rules: _vm.rules,
      color: "#e1b753",
      label: _vm.label,
      clearable: "",
    },
    on: { "click:clear": _vm.fireClearEvent, change: _vm.fireEnterEvent },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "py-2" }, [
              _c("div", { staticClass: "text-uppercase font-weight-medium" }, [
                _vm._v(" " + _vm._s(item.name) + " "),
              ]),
              _c("div", [
                _c("span", { staticClass: "grey--text text--darken-1" }, [
                  _vm._v("Phone:"),
                ]),
                _vm._v(" " + _vm._s(item.phone || "—") + " "),
              ]),
              _c("div", [
                _c("span", { staticClass: "grey--text text--darken-1" }, [
                  _vm._v("Email:"),
                ]),
                _vm._v(" " + _vm._s(item.email || "—") + " "),
              ]),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.defaultContact,
      callback: function ($$v) {
        _vm.defaultContact = $$v
      },
      expression: "defaultContact",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }