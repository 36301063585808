var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mt-0",
      attrs: {
        height: _vm.$vuetify.breakpoint.mdAndUp ? "385px" : "",
        "data-cy": "tender-details",
      },
    },
    [
      !_vm.tenderDetailEditing
        ? _c(
            "div",
            { staticClass: "pt-0" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "fixed-tabs": "", "show-arrows": "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab",
                    {
                      attrs: { href: "#tender" },
                      on: { click: _vm.getTenderDetails },
                    },
                    [
                      _vm._v(" Tender "),
                      !_vm.loadEditingDisabled
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "grey", small: "", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.tenderDetailEdit()
                                },
                              },
                            },
                            [_vm._v(" Edit ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-tab", { attrs: { href: "#accessorials" } }, [
                    _vm._v(" Accessorials"),
                  ]),
                  _c("v-tab", { attrs: { href: "#truckstopprice" } }, [
                    _vm._v(" Rates"),
                  ]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { key: "tender", attrs: { value: "tender" } },
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "pt-2 pb-0",
                          staticStyle: { height: "315px" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-responsive", {
                                    staticClass:
                                      "text-center blue lighten-2 rounded-circle d-inline-flex align-center justify-center",
                                    attrs: { height: "11", width: "11" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      style: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "border-left:1px solid #64B5F6; height:220px; margin-left:5px;"
                                        : "border-left:1px solid #64B5F6; height:230px; margin-left:5px; font-size: 10px",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ml-2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 grey--text text--darken-1",
                                            },
                                            [_vm._v("Customer Base")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-0 grey--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .customerBase
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2 ml-2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 grey--text text--darken-1",
                                            },
                                            [_vm._v(" Total Accessorial ")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-0 grey--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .customerTotalAccessorials
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-4 ml-2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 grey--text text--darken-1",
                                            },
                                            [_vm._v("Load Margin")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-0 grey--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails.loadMargin
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-4 ml-2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 grey--text text--darken-1",
                                            },
                                            [_vm._v("Percent Margin")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-0 grey--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .percentMargin
                                                  ) +
                                                  "% "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tender-detail-bottom-box pa-1",
                                        },
                                        [
                                          _vm.$vuetify.breakpoint.lgAndUp
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 ml-1 grey--text text--darken-3 pa-1",
                                                },
                                                [_vm._v(" Customer Total ")]
                                              )
                                            : _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 ml-1 grey--text text--darken-3 pa-1",
                                                },
                                                [_vm._v(" Cus. Total ")]
                                              ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-1 ml-1 blue--text text--lighten-1 font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .customerTotalPrice
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-responsive", {
                                    staticClass:
                                      "text-center blue lighten-2 rounded-circle d-inline-flex align-center justify-center",
                                    attrs: { height: "11", width: "11" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      style: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "border-left:1px solid #64B5F6; height:220px; margin-left:5px;"
                                        : "border-left:1px solid #64B5F6; height:230px; margin-left:5px; font-size: 10px",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ml-2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 grey--text text--darken-1",
                                            },
                                            [_vm._v(" Carrier Start Buy ")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-0 grey--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .carrierStartBuy
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2 ml-2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 grey--text text--darken-1",
                                            },
                                            [_vm._v(" Carrier Max Buy ")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-0 grey--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .carrierMaxBuy
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2 ml-2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 grey--text text--darken-1",
                                            },
                                            [_vm._v(" Total Accessorial ")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-0 grey--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .carrierTotalAccessorials
                                                  ) +
                                                  " ($" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .carrierTotalPrepaidAccessorials
                                                  ) +
                                                  " Prepaid) "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2 ml-2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-1 grey--text text--darken-1",
                                            },
                                            [_vm._v(" Carrier Line Haul ")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-0 grey--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .carrierLineHaul
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tender-detail-bottom-box pa-1",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-0 ml-1 grey--text text--darken-3 pa-1",
                                            },
                                            [_vm._v(" Carrier Total ")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "text--regular pa-1 ml-1 blue--text text--lighten-1 font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm.tenderDetails
                                                      .carrierTotalPrice
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "accessorials", attrs: { value: "accessorials" } },
                    [
                      !_vm.loadEditingDisabled
                        ? _c(
                            "v-expansion-panels",
                            {
                              attrs: { id: "top-panel" },
                              model: {
                                value: _vm.panelIndex,
                                callback: function ($$v) {
                                  _vm.panelIndex = $$v
                                },
                                expression: "panelIndex",
                              },
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "blue--text text-h5" },
                                    [_vm._v(" " + _vm._s(_vm.panelText) + " ")]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    { staticStyle: { height: "275px" } },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-form",
                                            {
                                              ref: "accessorial-form",
                                              model: {
                                                value: _vm.valid,
                                                callback: function ($$v) {
                                                  _vm.valid = $$v
                                                },
                                                expression: "valid",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mx-1" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "mt-3 py-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c(
                                                        "accessorial-type-auto-complete",
                                                        {
                                                          attrs: {
                                                            "default-type":
                                                              _vm.accTypeId,
                                                            rules: [
                                                              _vm.rules
                                                                .required,
                                                            ],
                                                          },
                                                          on: {
                                                            event:
                                                              _vm.setAccType,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.chargeToTypes,
                                                          rules: [
                                                            _vm.rules.required,
                                                          ],
                                                          clearable: "",
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "Apply To",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.chargeToChange,
                                                        },
                                                        model: {
                                                          value: _vm.chargeTo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.chargeTo = $$v
                                                          },
                                                          expression:
                                                            "chargeTo",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.chargeTo === 2
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              label: "Prepaid",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.isPrepaid,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.isPrepaid =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "isPrepaid",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mx-1" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.chargeTypes,
                                                          rules: [
                                                            _vm.rules.required,
                                                          ],
                                                          clearable: "",
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label: "Charge Type",
                                                        },
                                                        model: {
                                                          value: _vm.chargeType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.chargeType = $$v
                                                          },
                                                          expression:
                                                            "chargeType",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules: [
                                                            _vm.rules.required,
                                                            _vm.rules
                                                              .floatDigit,
                                                          ],
                                                          label: "Quantity",
                                                        },
                                                        model: {
                                                          value: _vm.quantity,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.quantity = $$v
                                                          },
                                                          expression:
                                                            "quantity",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "mx-1" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules: [
                                                            _vm.rules.required,
                                                            _vm.rules
                                                              .floatDigit,
                                                          ],
                                                          label: "Amount",
                                                        },
                                                        model: {
                                                          value: _vm.amount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.amount = $$v
                                                          },
                                                          expression: "amount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules: [
                                                            _vm.rules.required,
                                                          ],
                                                          label: "Reason",
                                                        },
                                                        model: {
                                                          value: _vm.reason,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.reason = $$v
                                                          },
                                                          expression: "reason",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "py-3" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "3",
                                                    lg: "3",
                                                    md: "4",
                                                    sm: "2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "white--text text--lighten-2 font-weight-bold ml-3",
                                                      attrs: {
                                                        color: "error",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: _vm.resetPanel,
                                                      },
                                                    },
                                                    [_vm._v(" Cancel ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "3",
                                                    lg: "3",
                                                    md: "4",
                                                    sm: "2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "white--text text--lighten-2 font-weight-bold",
                                                      attrs: {
                                                        color: "info",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.saveAccessorial,
                                                      },
                                                    },
                                                    [_vm._v(" Save ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.loadCustomerAccessorialList.length ||
                      _vm.loadCarrierAccessorialList.length
                        ? [
                            _c(
                              "v-card",
                              {
                                staticClass: "ma-0",
                                staticStyle: { overflow: "hidden auto" },
                                attrs: { flat: "", height: "280" },
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mt-3 justify-space-around" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "5" } },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "mx-3 mb-2 text-center text-h4 font-weight-medium",
                                              },
                                              [_vm._v(" Customer ")]
                                            ),
                                            _vm._l(
                                              _vm.loadCustomerAccessorialList,
                                              function (item, index) {
                                                return _c(
                                                  "v-list-item",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "my-1" },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Acc Type:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.accessorialTypeText
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Charge Type:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.chargeTypeDisplay
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Quantity:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.quantity
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v("Amount:")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.amount
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v("Total:")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.totalAmount.toFixed(
                                                                  2
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v("Reason:")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.reason
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v("Prepaid:")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.prepaid ===
                                                                  true
                                                                  ? "Yes"
                                                                  : "No"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "blue--text pa-0",
                                                                    attrs: {
                                                                      small: "",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openEditPanel(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Edit "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "red--text pa-0",
                                                                    attrs: {
                                                                      small: "",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteAccessorial(
                                                                            item.id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Delete "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider", {
                                      attrs: { vertical: "" },
                                    }),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "5" } },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "mx-3 mb-2 text-center text-h4 font-weight-medium",
                                              },
                                              [_vm._v(" Carrier ")]
                                            ),
                                            _vm._l(
                                              _vm.loadCarrierAccessorialList,
                                              function (item, index) {
                                                return _c(
                                                  "v-list-item",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "my-1" },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Acc Type:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.accessorialTypeText
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Charge Type:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.chargeTypeDisplay
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Quantity:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.quantity
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v("Amount:")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.amount
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v("Total:")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.totalAmount.toFixed(
                                                                  2
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v("Reason:")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.reason
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium",
                                                            },
                                                            [_vm._v("Prepaid:")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.prepaid ===
                                                                  true
                                                                  ? "Yes"
                                                                  : "No"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "blue--text pa-0",
                                                                    attrs: {
                                                                      small: "",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openEditPanel(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Edit "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "red--text pa-0",
                                                                    attrs: {
                                                                      small: "",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteAccessorial(
                                                                            item.id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Delete "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c("div", { staticClass: "text-center mt-4" }, [
                              _vm._v("No data"),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "v-tab-item",
                    {
                      key: "truckstopprice",
                      attrs: { value: "truckstopprice" },
                    },
                    [_c("truckstop-price")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "pt-0" }, [_c("tender-detail-edit")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }