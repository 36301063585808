var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    staticClass: "mt-n2",
    attrs: {
      items: _vm.types,
      "item-text": "name",
      "item-value": "id",
      loading: _vm.loading,
      rules: _vm.rules,
      color: "#e1b753",
      label: "Accessorial Type",
    },
    on: { change: _vm.fireEnterEvent },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "py-2" }, [
              _c("div", { staticClass: "text-uppercase font-weight-medium" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _c("div", [_vm._v(_vm._s(item.description))]),
              _c("div", [
                _vm._v("Reimbursement: " + _vm._s(item.reimbursement)),
              ]),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.defaultType,
      callback: function ($$v) {
        _vm.defaultType = $$v
      },
      expression: "defaultType",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }