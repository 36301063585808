var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pt-3 pb-0 pr-0",
              attrs: { cols: "12", sm: "7", md: "9" },
            },
            [
              _c(
                "v-list-item-subtitle",
                {
                  staticClass:
                    "blue--text text--lighten-2 pa-1 pl-3 white--text box-header",
                },
                [
                  _vm._v(" Consignee info "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", small: "", color: "grey" },
                      on: {
                        click: function ($event) {
                          return _vm.saveConsigneeUpdate()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "px-4 pt-6",
          staticStyle: { height: "162px", "overflow-y": "auto" },
        },
        [
          _c(
            "v-form",
            {
              ref: "consigneeInfoEdit-form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.consigneeInfoFormValid,
                callback: function ($$v) {
                  _vm.consigneeInfoFormValid = $$v
                },
                expression: "consigneeInfoFormValid",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0 pt-1 pb-0", attrs: { cols: "12" } },
                    [
                      _c("location-auto-complete", {
                        attrs: {
                          direction: "dest",
                          "form-type": "3",
                          model: _vm.consigneeInfo,
                          "stop-sequence": "2",
                          "customer-id": _vm.shipmentDetail.customer.id,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "12", md: "6", lg: "4" },
                    },
                    [
                      _vm.consigneeInfo.id
                        ? _c("v-text-field", {
                            staticClass: "pt-0",
                            attrs: {
                              label: "Contact Phone",
                              rules: [_vm.rules.phone],
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveConsigneeUpdate()
                              },
                            },
                            model: {
                              value: _vm.consigneeInfo.contactPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.consigneeInfo, "contactPhone", $$v)
                              },
                              expression: "consigneeInfo.contactPhone",
                            },
                          })
                        : _c("v-text-field", {
                            staticClass: "pt-0",
                            attrs: { disabled: "", label: "Contact Phone" },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "12", md: "6", lg: "4" },
                    },
                    [
                      _vm.loadInfo
                        ? _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##:##",
                                expression: "'##:##'",
                              },
                            ],
                            staticClass: "pt-0",
                            attrs: {
                              label: "Delivery Early",
                              rules: [_vm.rules.time],
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveConsigneeUpdate()
                              },
                            },
                            model: {
                              value: _vm.loadInfo.consigneeEarlyTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.loadInfo,
                                  "consigneeEarlyTime",
                                  $$v
                                )
                              },
                              expression: "loadInfo.consigneeEarlyTime",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "12", md: "6", lg: "4" },
                    },
                    [
                      _vm.loadInfo
                        ? _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##:##",
                                expression: "'##:##'",
                              },
                            ],
                            staticClass: "pt-0",
                            attrs: {
                              label: "Delivery Late",
                              rules: [_vm.rules.time],
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveConsigneeUpdate()
                              },
                            },
                            model: {
                              value: _vm.loadInfo.consigneeLateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.loadInfo, "consigneeLateTime", $$v)
                              },
                              expression: "loadInfo.consigneeLateTime",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "12", md: "6", lg: "4" },
                    },
                    [
                      _vm.consigneeInfo.id
                        ? _c("v-text-field", {
                            staticClass: "pt-0",
                            attrs: { label: "Location Hours" },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveConsigneeUpdate()
                              },
                            },
                            model: {
                              value: _vm.consigneeInfo.hours,
                              callback: function ($$v) {
                                _vm.$set(_vm.consigneeInfo, "hours", $$v)
                              },
                              expression: "consigneeInfo.hours",
                            },
                          })
                        : _c("v-text-field", {
                            staticClass: "pt-0",
                            attrs: { disabled: "", label: "Location Hours" },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "12", md: "6", lg: "4" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0",
                        attrs: {
                          label: "Delivery Number",
                          rules: [_vm.rules.alphaNumeric],
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.saveConsigneeUpdate()
                          },
                        },
                        model: {
                          value: _vm.loadInfo.deliveryNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.loadInfo, "deliveryNumber", $$v)
                          },
                          expression: "loadInfo.deliveryNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        staticClass: "pt-0",
                        attrs: {
                          label: "Notes",
                          "auto-grow": "",
                          rows: "4",
                          "row-height": "30",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.saveConsigneeUpdate()
                          },
                        },
                        model: {
                          value: _vm.loadInfo.consigneeNotes,
                          callback: function ($$v) {
                            _vm.$set(_vm.loadInfo, "consigneeNotes", $$v)
                          },
                          expression: "loadInfo.consigneeNotes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }