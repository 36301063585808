var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tooltipContent
    ? _c(
        "v-tooltip",
        {
          attrs: {
            disabled: _vm.$vuetify.breakpoint.lgAndUp
              ? _vm.tooltipContent.length < 15
              : _vm.tooltipContent.length < 5,
            bottom: "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "div",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "text-truncate truncate",
                            style: _vm.type === "text" && { marginLeft: "0" },
                          },
                          "div",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_vm._v(" " + _vm._s(_vm.tooltipContent) + " ")]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            4127204611
          ),
        },
        [
          _c("div", { staticClass: "box" }, [
            _vm._v(" " + _vm._s(_vm.tooltipContent) + " "),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }