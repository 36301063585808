var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-3 pb-0 pr-0", attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "v-list-item-subtitle",
                {
                  staticClass:
                    "blue--text text--lighten-2 pa-1 pl-3 white--text box-header",
                },
                [
                  _vm._v(" Shipper info "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey", small: "", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.saveShipperUpdate()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "px-4 pt-6",
          staticStyle: { height: "162px", "overflow-y": "auto" },
        },
        [
          _c(
            "v-form",
            {
              ref: "shipperInfoEdit-form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.shipperInfoFormValid,
                callback: function ($$v) {
                  _vm.shipperInfoFormValid = $$v
                },
                expression: "shipperInfoFormValid",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-3 pt-1 pb-0", attrs: { cols: "12" } },
                    [
                      _c("location-auto-complete", {
                        attrs: {
                          "customer-id": _vm.shipmentDetail.customer.id,
                          model: _vm.shipperInfo,
                          direction: "source",
                          "form-type": "2",
                          "stop-sequence": "1",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pt-0 pb-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", lg: "4", md: "6", sm: "12" },
                    },
                    [
                      _vm.shipperInfo.id
                        ? _c("v-text-field", {
                            staticClass: "pt-0",
                            attrs: {
                              rules: [_vm.rules.phone],
                              label: "Contact Phone",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveShipperUpdate()
                              },
                            },
                            model: {
                              value: _vm.shipperInfo.contactPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.shipperInfo, "contactPhone", $$v)
                              },
                              expression: "shipperInfo.contactPhone",
                            },
                          })
                        : _c("v-text-field", {
                            staticClass: "pt-0",
                            attrs: { disabled: "", label: "Contact Phone" },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", lg: "4", md: "6", sm: "12" },
                    },
                    [
                      _vm.loadInfo
                        ? _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##:##",
                                expression: "'##:##'",
                              },
                            ],
                            staticClass: "pt-0",
                            attrs: {
                              rules: [_vm.rules.time],
                              label: "Pickup Early",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveShipperUpdate()
                              },
                            },
                            model: {
                              value: _vm.loadInfo.shipperEarlyTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.loadInfo, "shipperEarlyTime", $$v)
                              },
                              expression: "loadInfo.shipperEarlyTime",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", lg: "4", md: "6", sm: "12" },
                    },
                    [
                      _vm.loadInfo
                        ? _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##:##",
                                expression: "'##:##'",
                              },
                            ],
                            staticClass: "pt-0",
                            attrs: {
                              rules: [_vm.rules.time],
                              label: "Pickup Late",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveShipperUpdate()
                              },
                            },
                            model: {
                              value: _vm.loadInfo.shipperLateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.loadInfo, "shipperLateTime", $$v)
                              },
                              expression: "loadInfo.shipperLateTime",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", lg: "4", md: "6", sm: "12" },
                    },
                    [
                      _vm.shipperInfo.id
                        ? _c("v-text-field", {
                            staticClass: "pt-0",
                            attrs: { label: "Location Hours" },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveShipperUpdate()
                              },
                            },
                            model: {
                              value: _vm.shipperInfo.hours,
                              callback: function ($$v) {
                                _vm.$set(_vm.shipperInfo, "hours", $$v)
                              },
                              expression: "shipperInfo.hours",
                            },
                          })
                        : _c("v-text-field", {
                            staticClass: "pt-0",
                            attrs: { disabled: "", label: "Location Hours" },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", lg: "4", md: "6", sm: "12" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0",
                        attrs: {
                          rules: [_vm.rules.alphaNumeric],
                          label: "Pickup Number",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.saveShipperUpdate()
                          },
                        },
                        model: {
                          value: _vm.loadInfo.shipperPickupNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.loadInfo, "shipperPickupNumber", $$v)
                          },
                          expression: "loadInfo.shipperPickupNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        staticClass: "pt-0 pb-0",
                        attrs: {
                          "auto-grow": "",
                          label: "Notes",
                          "row-height": "30",
                          rows: "4",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.saveShipperUpdate()
                          },
                        },
                        model: {
                          value: _vm.loadInfo.shipperNotes,
                          callback: function ($$v) {
                            _vm.$set(_vm.loadInfo, "shipperNotes", $$v)
                          },
                          expression: "loadInfo.shipperNotes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }