var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pl-0 pt-0 pr-0 pb-0" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pt-3 pb-0",
              attrs: {
                sm: _vm.$vuetify.breakpoint.lgAndUp ? "7" : "8",
                cols: "12",
              },
            },
            [
              _c(
                "v-list-item-subtitle",
                {
                  staticClass:
                    "blue--text text--lighten-2 pa-1 pl-3 white--text box-header",
                },
                [
                  _vm._v(" Carrier info "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey", small: "", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.saveCarrierUpdate()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "px-4 pt-3",
          staticStyle: { height: "174px", "overflow-y": "auto" },
        },
        [
          _c(
            "v-form",
            {
              ref: "carrierInfoEdit-form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.carrierInfoFormValid,
                callback: function ($$v) {
                  _vm.carrierInfoFormValid = $$v
                },
                expression: "carrierInfoFormValid",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pr-0 pt-0 pb-0",
                      attrs: { cols: "12", lg: "10", md: "12", sm: "12" },
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pt-0 pl-0 pb-0" },
                        [
                          _c("carrier-auto-complete", {
                            attrs: { model: _vm.carrierInfo },
                            on: { event: _vm.selectedCarrier },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", lg: "6", md: "6", sm: "12" },
                    },
                    [
                      _c("carrier-contact-select", {
                        attrs: {
                          data: _vm.dispatchContacts,
                          "default-contact": _vm.defaultDispatcher,
                          label: "Dispatch contact",
                        },
                        on: { event: _vm.setCarrierDispatchContact },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", lg: "6", md: "6", sm: "12" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0",
                        attrs: {
                          rules: [_vm.rules.alphaNumeric],
                          label: "Driver Name",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.saveCarrierUpdate()
                          },
                        },
                        model: {
                          value: _vm.shipmentDetail.driverName,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipmentDetail, "driverName", $$v)
                          },
                          expression: "shipmentDetail.driverName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", lg: "6", md: "6", sm: "12" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0",
                        attrs: {
                          rules: [_vm.rules.phone],
                          label: "Driver Phone",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.saveCarrierUpdate()
                          },
                        },
                        model: {
                          value: _vm.shipmentDetail.driverPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipmentDetail, "driverPhone", $$v)
                          },
                          expression: "shipmentDetail.driverPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }