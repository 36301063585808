var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.carrierInfoEdit
    ? _c(
        "div",
        { staticClass: "pt-3", attrs: { "data-cy": "carrier-info" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0 pr-0",
                  attrs: {
                    sm: _vm.$vuetify.breakpoint.mdAndUp ? "7" : "8",
                    cols: "12",
                  },
                },
                [
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "blue--text text--lighten-2 pa-1 pl-3 pb-4 white--text box-header",
                    },
                    [
                      _vm._v(" Carrier info "),
                      _vm.shipmentDetail.isPaid
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "text-truncate truncate",
                                                staticStyle: {
                                                  "margin-left": "0px",
                                                },
                                              },
                                              "span",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { medium: "" } },
                                              [_vm._v(" mdi-cash-check ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3494806978
                              ),
                            },
                            [
                              _c("div", { staticClass: "box" }, [
                                _vm._v(
                                  " Carrier paid $" +
                                    _vm._s(
                                      _vm.shipmentDetail.carrierPaymentAmount.toFixed(
                                        2
                                      )
                                    ) +
                                    " via " +
                                    _vm._s(
                                      _vm.shipmentDetail.carrierPaymentType
                                    ) +
                                    " on " +
                                    _vm._s(_vm.shipmentDetail.paidDate) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.disableStatusButton
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "grey", small: "", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.carrierInfoEditing()
                                },
                              },
                            },
                            [_vm._v(" Edit ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style:
                _vm.$vuetify.breakpoint.mdAndUp && _vm.showEdit
                  ? "height: 162px; overflow-y: auto;"
                  : "",
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Carrier")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _vm.carrierInfo
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Carrier Detail",
                                          params: { id: _vm.carrierInfo.id },
                                        },
                                      },
                                    },
                                    [
                                      _c("tooltip", {
                                        attrs: {
                                          "tooltip-content": _vm.carrierInfo
                                            ? _vm.carrierInfo.name
                                            : "Not Assigned",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("tooltip", {
                                    attrs: {
                                      "tooltip-content": _vm.carrierInfo
                                        ? _vm.carrierInfo.name
                                        : "Not Assigned",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("MC")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content": _vm.carrierInfo
                                    ? _vm.carrierInfo.mcNumber
                                    : null,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("DOT")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content": _vm.carrierInfo
                                    ? _vm.carrierInfo.dotNumber
                                    : null,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Phone")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content": _vm.carrierInfo
                                    ? _vm.carrierInfo.phone
                                    : null,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Email")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content": _vm.carrierInfo
                                    ? _vm.carrierInfo.email
                                    : null,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Dispatcher")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.shipmentDetail.dispatcher,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _vm.$vuetify.breakpoint.lgAndUp
                            ? _c(
                                "p",
                                {
                                  staticClass: "mb-1 grey--text text--darken-1",
                                },
                                [_vm._v(" Dispatch Phone ")]
                              )
                            : _c(
                                "p",
                                {
                                  staticClass: "mb-1 grey--text text--darken-1",
                                },
                                [_vm._v("Disp. Phone")]
                              ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.shipmentDetail.dispatchPhone,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Dispatch Email")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.shipmentDetail.dispatchEmail,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Driver Phone")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.shipmentDetail.driverPhone,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Driver Name")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.shipmentDetail.driverName,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("carrier-info-edit")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }