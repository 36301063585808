var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 pt-3", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "commodity-form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.commodityFormValid,
            callback: function ($$v) {
              _vm.commodityFormValid = $$v
            },
            expression: "commodityFormValid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-0 pb-0", attrs: { cols: "10" } },
                [
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "blue--text text--lighten-2 pa-1 pl-3 white--text box-header",
                    },
                    [
                      _vm.$vuetify.breakpoint.lgAndUp
                        ? _c("span", [_vm._v("Commodity and Dimensions")])
                        : _c("span", [_vm._v("Comm & Dims")]),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", small: "", color: "grey" },
                          on: {
                            click: function ($event) {
                              return _vm.saveCommodityAndDimensionsUpdate()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "hgt320 pt-3" },
            [
              _c(
                "v-card-text",
                { staticClass: "grey--text text--darken-4 pl-0 pt-0 pb-0" },
                [_vm._v(" Dimensions ")]
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-0",
                        attrs: {
                          label: "Length",
                          rules: [_vm.rules.floatDigit, _vm.rules.required],
                        },
                        model: {
                          value: _vm.commodityInfo.loadLength,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "loadLength", $$v)
                          },
                          expression: "commodityInfo.loadLength",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-0",
                        attrs: {
                          label: "Height",
                          rules: [_vm.rules.floatDigit],
                        },
                        model: {
                          value: _vm.commodityInfo.loadHeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "loadHeight", $$v)
                          },
                          expression: "commodityInfo.loadHeight",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-0",
                        attrs: {
                          label: "Width",
                          rules: [_vm.rules.floatDigit],
                        },
                        model: {
                          value: _vm.commodityInfo.loadWidth,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "loadWidth", $$v)
                          },
                          expression: "commodityInfo.loadWidth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-0",
                        attrs: {
                          label: "Weight",
                          rules: [
                            _vm.rules.alphaNumericWithComma,
                            _vm.rules.required,
                          ],
                        },
                        model: {
                          value: _vm.commodityInfo.loadWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "loadWeight", $$v)
                          },
                          expression: "commodityInfo.loadWeight",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "grey--text text--darken-4 pl-0 py-0 pt-3" },
                [_vm._v(" Commodity ")]
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", sm: "12", md: "12", lg: "6" },
                    },
                    [
                      _c("v-textarea", {
                        staticClass: "pb-0",
                        attrs: {
                          clearable: "",
                          "no-resize": "",
                          label: "Description",
                        },
                        model: {
                          value: _vm.commodityInfo.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "description", $$v)
                          },
                          expression: "commodityInfo.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", sm: "12", md: "12", lg: "6" },
                    },
                    [
                      _c("v-textarea", {
                        staticClass: "pb-0",
                        attrs: {
                          clearable: "",
                          "no-resize": "",
                          label: "Commodity",
                        },
                        model: {
                          value: _vm.commodityInfo.commodity,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "commodity", $$v)
                          },
                          expression: "commodityInfo.commodity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-0",
                        attrs: {
                          label: "Miles",
                          rules: [_vm.rules.floatDigit],
                        },
                        model: {
                          value: _vm.commodityInfo.miles,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "miles", $$v)
                          },
                          expression: "commodityInfo.miles",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-0",
                        attrs: {
                          label: "Quantity",
                          rules: [_vm.rules.floatDigit],
                        },
                        model: {
                          value: _vm.commodityInfo.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "quantity", $$v)
                          },
                          expression: "commodityInfo.quantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-0",
                        attrs: {
                          label: "Temperature",
                          rules: [_vm.rules.floatDigit],
                        },
                        model: {
                          value: _vm.commodityInfo.temperature,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "temperature", $$v)
                          },
                          expression: "commodityInfo.temperature",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "3" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-0",
                        attrs: {
                          label: "Declared value",
                          rules: [_vm.rules.floatDigit],
                        },
                        model: {
                          value: _vm.commodityInfo.declaredValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.commodityInfo, "declaredValue", $$v)
                          },
                          expression: "commodityInfo.declaredValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }