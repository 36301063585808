var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showMap
    ? _c("div", { attrs: { id: "map" } }, [
        _c("div", {
          ref: "hereMap",
          style: `height:${_vm.height};width:${_vm.width}`,
          attrs: { id: "mapContainer" },
        }),
      ])
    : _c(
        "div",
        {
          staticStyle: {
            background: "rgba(0, 0, 0, 0.12)",
            "text-align": "center",
            color: "rgba(0, 0, 0, 0.26)",
            "font-size": "25px",
          },
          style: `height:${_vm.height};width:${_vm.width};line-height:${_vm.height};`,
        },
        [_vm._v(" Map not available ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }