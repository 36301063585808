var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.consigneeDetailEdit
    ? _c(
        "div",
        { staticClass: "pt-3", attrs: { "data-cy": "consignee-info" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0 pr-0",
                  attrs: {
                    sm: _vm.$vuetify.breakpoint.lgAndUp ? "7" : "9",
                    cols: "12",
                  },
                },
                [
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "blue--text text--lighten-2 pa-1 pl-3 pb-4 white--text box-header",
                    },
                    [
                      _vm._v(" Consignee info "),
                      !_vm.loadEditingDisabled
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "grey", small: "", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.consigneeInfoEditing()
                                },
                              },
                            },
                            [_vm._v(" Edit ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style:
                _vm.$vuetify.breakpoint.mdAndUp && _vm.showEdit
                  ? "height: 150px; overflow-y: auto;"
                  : "",
            },
            [
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _vm.consignee.name
                    ? _c(
                        "v-card-text",
                        { staticClass: "text--regular pa-0 py-0" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Location Detail",
                                  params: { id: _vm.consignee.id },
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.consignee.name) + " ")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-card-text",
                        { staticClass: "text--regular pa-0 pt-2" },
                        [_vm._v(" Assign Consignee ")]
                      ),
                  _c(
                    "v-list-item-subtitle",
                    { staticClass: "grey--text text--darken-3 py-2" },
                    [
                      _vm.consignee.address
                        ? _c("span", [_vm._v(_vm._s(_vm.consignee.address))])
                        : _vm._e(),
                      _vm.consignee.city
                        ? _c("span", [_vm._v(" " + _vm._s(_vm.consignee.city))])
                        : _vm._e(),
                      _vm.consignee.stateProvinceRegion
                        ? _c("span", [
                            _vm._v(
                              ", " + _vm._s(_vm.consignee.stateProvinceRegion)
                            ),
                          ])
                        : _vm._e(),
                      _vm.consignee.zipcode
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.consignee.zipcode)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6", lg: "4", md: "6", sm: "6" } },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Contact Phone")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content": _vm.consignee.contactPhone,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", lg: "4", md: "6", sm: "6" } },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Delivery Early")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.loadInfo.consigneeEarlyTime,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", lg: "4", md: "6", sm: "6" } },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Delivery Late")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.loadInfo.consigneeLateTime,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Location Hours")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content": _vm.consignee.hours,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Delivery Number")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.loadInfo.deliveryNumber,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "xs pt-0 pb-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Notes")]
                          ),
                          _vm.loadInfo.consigneeNotes
                            ? _c(
                                "v-list-item-subtitle",
                                { staticClass: "grey--text text--darken-4" },
                                [
                                  (
                                    _vm.$vuetify.breakpoint.lgAndUp
                                      ? _vm.loadInfo.consigneeNotes.length < 15
                                      : _vm.loadInfo.consigneeNotes.length < 5
                                  )
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.loadInfo.consigneeNotes
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "v-dialog",
                                          {
                                            attrs: {
                                              "max-width": "300px",
                                              scrollable: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color: "black",
                                                                dark: "",
                                                                plain: "",
                                                                text: "",
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.loadInfo.consigneeNotes.substring(
                                                                  0,
                                                                  40
                                                                )
                                                              ) +
                                                              "... "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              51188031
                                            ),
                                            model: {
                                              value: _vm.dialog,
                                              callback: function ($$v) {
                                                _vm.dialog = $$v
                                              },
                                              expression: "dialog",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c("v-card-title", [
                                                  _vm._v(" Consignee Notes"),
                                                ]),
                                                _c("v-divider"),
                                                _c("v-card-text", {
                                                  staticStyle: {
                                                    height: "300px",
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.loadInfo.consigneeNotes.replace(
                                                        /(?:\r\n|\r|\n)/g,
                                                        "<br>"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("consignee-info-edit")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }