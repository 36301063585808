var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "pl-3", attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-form",
            {
              ref: "tender-form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.tenderFormValid,
                callback: function ($$v) {
                  _vm.tenderFormValid = $$v
                },
                expression: "tenderFormValid",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-4 px-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass:
                            "blue--text text--lighten-2 pa-1 pl-3 box-header mb-n4",
                        },
                        [
                          _vm._v(" Tender Detail "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", small: "", color: "grey" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveLoadUpdate()
                                },
                              },
                            },
                            [_vm._v(" Save ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-responsive", {
                        staticClass:
                          "text-center blue lighten-2 rounded-circle d-inline-flex align-center justify-center",
                        attrs: { height: "11", width: "11" },
                      }),
                      _c(
                        "div",
                        {
                          style: _vm.$vuetify.breakpoint.lgAndUp
                            ? "border-left:1px solid #64B5F6; height:280px; margin-left:5px;"
                            : "border-left:1px solid #64B5F6; height:280px; margin-left:5px; font-size: 10px",
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-0 ml-2",
                            attrs: {
                              label: "Customer Base",
                              rules: [_vm.rules.amount],
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveLoadUpdate()
                              },
                            },
                            model: {
                              value: _vm.tenderDetails.customerBase,
                              callback: function ($$v) {
                                _vm.$set(_vm.tenderDetails, "customerBase", $$v)
                              },
                              expression: "tenderDetails.customerBase",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "ml-2" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-1 grey--text text--darken-1",
                                },
                                [_vm._v("Load Margin")]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "text--regular pa-0 grey--text text--darken-4",
                                },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(_vm.tenderDetails.loadMargin) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-4 ml-2" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-1 grey--text text--darken-1",
                                },
                                [_vm._v("Percent Margin")]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "text--regular pa-0 grey--text text--darken-4",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.tenderDetails.percentMargin) +
                                      "% "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "tender-detail-bottom-box pa-1" },
                            [
                              _vm.$vuetify.breakpoint.lgAndUp
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-0 ml-1 grey--text text--darken-3 pa-1",
                                    },
                                    [_vm._v(" Customer Total ")]
                                  )
                                : _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-0 ml-1 grey--text text--darken-3 pa-1",
                                    },
                                    [_vm._v(" Cus. Total ")]
                                  ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "text--regular pa-1 ml-1 blue--text text--lighten-1 font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        _vm.tenderDetails.customerTotalPrice
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-responsive", {
                        staticClass:
                          "text-center blue lighten-2 rounded-circle d-inline-flex align-center justify-center",
                        attrs: { height: "11", width: "11" },
                      }),
                      _c(
                        "div",
                        {
                          style: _vm.$vuetify.breakpoint.lgAndUp
                            ? "border-left:1px solid #64B5F6; height:280px; margin-left:5px;"
                            : "border-left:1px solid #64B5F6; height:280px; margin-left:5px; font-size: 10px",
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: {
                                    cols: _vm.$vuetify.breakpoint.lgAndUp
                                      ? "6"
                                      : "12",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-0 ml-2 pr-1",
                                    attrs: {
                                      label: "Carrier Start Buy",
                                      rules: [_vm.rules.amount],
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.saveLoadUpdate()
                                      },
                                    },
                                    model: {
                                      value: _vm.tenderDetails.carrierStartBuy,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tenderDetails,
                                          "carrierStartBuy",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tenderDetails.carrierStartBuy",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: {
                                    cols: _vm.$vuetify.breakpoint.lgAndUp
                                      ? "6"
                                      : "12",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-0 ml-2 pr-1",
                                    attrs: {
                                      label: "Carrier Max Buy",
                                      rules: [_vm.rules.amount],
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.saveLoadUpdate()
                                      },
                                    },
                                    model: {
                                      value: _vm.tenderDetails.carrierMaxBuy,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tenderDetails,
                                          "carrierMaxBuy",
                                          $$v
                                        )
                                      },
                                      expression: "tenderDetails.carrierMaxBuy",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-text-field", {
                            staticClass: "mt-0 ml-2 pr-1",
                            attrs: {
                              label: "Carrier Line Haul",
                              rules: [_vm.rules.amount],
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveLoadUpdate()
                              },
                            },
                            model: {
                              value: _vm.tenderDetails.carrierLineHaul,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tenderDetails,
                                  "carrierLineHaul",
                                  $$v
                                )
                              },
                              expression: "tenderDetails.carrierLineHaul",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "tender-detail-bottom-box pa-1" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 ml-1 grey--text text--darken-3 pa-1",
                                },
                                [_vm._v(" Carrier Total ")]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "text--regular pa-1 ml-1 blue--text text--lighten-1 font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        _vm.tenderDetails.carrierTotalPrice
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }