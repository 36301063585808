var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "align-center" },
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: { cols: "3", lg: "3", md: "3", xl: "3" },
            },
            [
              _c("v-text-field", {
                staticClass: "pt-0",
                attrs: { label: "Margin" },
                model: {
                  value: _vm.margin,
                  callback: function ($$v) {
                    _vm.margin = $$v
                  },
                  expression: "margin",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: { cols: "6", lg: "6", md: "5", xl: "6" },
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.radiusData,
                  "item-text": "text",
                  "item-value": "value",
                  label: "Radius",
                },
                model: {
                  value: _vm.radius,
                  callback: function ($$v) {
                    _vm.radius = $$v
                  },
                  expression: "radius",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: { cols: "3", lg: "3", md: "3", xl: "3" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "green", "x-small": "" },
                  on: {
                    click: function ($event) {
                      return _vm.fetchTruckStopPrice()
                    },
                  },
                },
                [_vm._v(" Fetch Rates ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticClass: "pt-3" }, [_vm._v("Pricing: shipper / carrier")]),
      _c(
        "v-row",
        {
          style: _vm.$vuetify.breakpoint.md
            ? "height: 169px; overflow-y: auto;"
            : "height: 205px",
        },
        [
          _c("v-col", { staticClass: "pt-0", attrs: { cols: "5" } }, [
            _c("div", [_c("h3", [_vm._v("Paid")])]),
            _c("b", { staticClass: "float-left pr-1" }, [_vm._v(" 30 Days: ")]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Reports: " + _vm._s(_vm.priceRate.paid30dReports)),
            ]),
            _vm.priceRate.paid30dReports
              ? _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " $" +
                      _vm._s(_vm.priceRate.paid30dShipperRate) +
                      " / $" +
                      _vm._s(_vm.priceRate.paid30dTruckerRate) +
                      " (" +
                      _vm._s(_vm.priceRate.paid30dShipper) +
                      " / " +
                      _vm._s(_vm.priceRate.paid30dTrucker) +
                      ") "
                  ),
                ])
              : _vm._e(),
            _c("b", { staticClass: "float-left pr-1" }, [_vm._v(" 90 Days: ")]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Reports: " + _vm._s(_vm.priceRate.paid90dReports)),
            ]),
            _vm.priceRate.paid90dReports
              ? _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " $" +
                      _vm._s(_vm.priceRate.paid90dShipperRate) +
                      " / $" +
                      _vm._s(_vm.priceRate.paid90dTruckerRate) +
                      " (" +
                      _vm._s(_vm.priceRate.paid90dShipper) +
                      " / " +
                      _vm._s(_vm.priceRate.paid90dTrucker) +
                      ") "
                  ),
                ])
              : _vm._e(),
            _c("b", { staticClass: "float-left pr-1" }, [
              _vm._v(" 365 Days: "),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Reports: " + _vm._s(_vm.priceRate.paid365dReports)),
            ]),
            _vm.priceRate.paid365dReports
              ? _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " $" +
                      _vm._s(_vm.priceRate.paid365dShipperRate) +
                      " / $" +
                      _vm._s(_vm.priceRate.paid365dTruckerRate) +
                      " (" +
                      _vm._s(_vm.priceRate.paid365dShipper) +
                      " / " +
                      _vm._s(_vm.priceRate.paid365dTrucker) +
                      ") "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("v-col", { staticClass: "pt-0", attrs: { cols: "7" } }, [
            _c("div", [_c("h3", [_vm._v("Posted")])]),
            _c("b", { staticClass: "float-left pr-1" }, [_vm._v(" 7 Days: ")]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Reports: " + _vm._s(_vm.priceRate.posted7dReports)),
            ]),
            _vm.priceRate.posted7dReports
              ? _c(
                  "p",
                  { staticClass: "mb-0" },
                  [
                    _c("tooltip", {
                      attrs: {
                        "tooltip-content": _vm.joinString(
                          _vm.priceRate.posted7dShipperRate,
                          _vm.priceRate.posted7dTruckerRate,
                          _vm.priceRate.posted7dShipper,
                          _vm.priceRate.posted7dTrucker
                        ),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("b", { staticClass: "float-left pr-1" }, [_vm._v(" 15 Days: ")]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Reports: " + _vm._s(_vm.priceRate.posted15dReports)),
            ]),
            _vm.priceRate.posted15dReports
              ? _c(
                  "p",
                  { staticClass: "mb-0" },
                  [
                    _c("tooltip", {
                      attrs: {
                        "tooltip-content": _vm.joinString(
                          _vm.priceRate.posted15dShipperRate,
                          _vm.priceRate.posted15dTruckerRate,
                          _vm.priceRate.posted15dShipper,
                          _vm.priceRate.posted15dTrucker
                        ),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("b", { staticClass: "float-left pr-1" }, [_vm._v(" 30 Days: ")]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Reports: " + _vm._s(_vm.priceRate.posted30dReports)),
            ]),
            _vm.priceRate.posted30dReports
              ? _c(
                  "p",
                  { staticClass: "mb-0" },
                  [
                    _c("tooltip", {
                      attrs: {
                        "tooltip-content": _vm.joinString(
                          _vm.priceRate.posted30dShipperRate,
                          _vm.priceRate.posted30dTruckerRate,
                          _vm.priceRate.posted30dShipper,
                          _vm.priceRate.posted30dTrucker
                        ),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("b", { staticClass: "float-left pr-1" }, [_vm._v(" 90 Days: ")]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Reports: " + _vm._s(_vm.priceRate.posted90dReports)),
            ]),
            _vm.priceRate.posted90dReports
              ? _c(
                  "p",
                  { staticClass: "mb-0" },
                  [
                    _c("tooltip", {
                      attrs: {
                        "tooltip-content": _vm.joinString(
                          _vm.priceRate.posted90dShipperRate,
                          _vm.priceRate.posted90dTruckerRate,
                          _vm.priceRate.posted90dShipper,
                          _vm.priceRate.posted90dTrucker
                        ),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("b", { staticClass: "float-left pr-1" }, [
              _vm._v(" 365 Days: "),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Reports: " + _vm._s(_vm.priceRate.posted365dReports)),
            ]),
            _vm.priceRate.posted365dReports
              ? _c(
                  "p",
                  { staticClass: "mb-0" },
                  [
                    _c("tooltip", {
                      attrs: {
                        "tooltip-content": _vm.joinString(
                          _vm.priceRate.posted365dShipperRate,
                          _vm.priceRate.posted365dTruckerRate,
                          _vm.priceRate.posted365dShipper,
                          _vm.priceRate.posted365dTrucker
                        ),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }