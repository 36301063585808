var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.commodityAndDimensionsInfoEdit
    ? _c(
        "v-container",
        { staticClass: "pt-0", attrs: { "data-cy": "comm-dims", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-3 py-0", attrs: { cols: "10" } },
                [
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "blue--text text--lighten-2 pa-1 py-4 pl-0 white--text box-header",
                    },
                    [
                      _vm.$vuetify.breakpoint.lgAndUp
                        ? _c("span", [_vm._v("Commodity and Dimensions")])
                        : _c("span", [_vm._v("Comm & Dims")]),
                      !_vm.loadEditingDisabled
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "grey", small: "", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.commodityAndDimensionsInfoEditing()
                                },
                              },
                            },
                            [_vm._v(" Edit ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-3" },
            [
              _c(
                "v-container",
                {
                  class: _vm.$vuetify.breakpoint.mdAndUp
                    ? "pa-0 hgt320"
                    : "pa-0",
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "grey--text text--darken-4 pl-0 pt-0" },
                    [_vm._v(" Dimensions ")]
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "3", md: "3", sm: "3" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Length")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4 mb-1" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.commodityInfo.loadLength) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "3", md: "3", sm: "3" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Height")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4 mb-1" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.commodityInfo.loadHeight) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "3", md: "3", sm: "3" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Width")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4 mb-1" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.commodityInfo.loadWidth) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "3", md: "3", sm: "3" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Weight")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4 mb-1" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.commodityInfo.loadWeight) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "grey--text text--darken-4 pl-0 py-1" },
                    [_vm._v(" Commodity ")]
                  ),
                  _c("v-row"),
                  _c(
                    "v-row",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "6", md: "12", sm: "12" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Description")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4 mb-1" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.commodityInfo.description,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "6", md: "12", sm: "12" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Commodity")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4 mb-1" },
                            [
                              _c("tooltip", {
                                attrs: {
                                  "tooltip-content":
                                    _vm.commodityInfo.commodity,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "3", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Miles")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.commodityInfo.miles) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "3", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Quantity")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.commodityInfo.quantity) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "3", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Temp")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.commodityInfo.temperature) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", lg: "3", md: "6", sm: "6" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-1 grey--text text--darken-1" },
                            [_vm._v("Value")]
                          ),
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-4" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.commodityInfo.declaredValue) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("commodity-dimensions-edit")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }